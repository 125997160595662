import { useEffect, useState } from "react"

/**
 * This hook listens for navigation-related keydown events and
 * returns `true` if any have been detected.
 */
export default () => {
  const [keyboardNavDetected, setKeyboardNavDetected] = useState(false)

  useEffect(() => {
    const onKeydown = (e) => {
      if (
        !["input", "select", "textarea"].includes(
          e.target.nodeName.toLowerCase()
        ) &&
        NAVIGATION_CODES.includes(e.code)
      ) {
        setKeyboardNavDetected(true)
        document.removeEventListener("keydown", onKeydown, false)
      }
    }

    document.addEventListener("keydown", onKeydown, false)

    return () => {
      document.removeEventListener("keydown", onKeydown, false)
    }
  }, [])

  return keyboardNavDetected
}

const NAVIGATION_CODES = [
  "ArrowUp",
  "ArrowRight",
  "ArrowDown",
  "ArrowLeft",
  "Tab",
  "Space",
  "Home",
  "End",
  "PageDown",
  "PageUp",
  "Numpad1", // End
  "Numpad2", // Down
  "Numpad3", // Page Down
  "Numpad4", // Left
  "Numpad6", // Right
  "Numpad7", // Home
  "Numpad8", // Up
  "Numpad9", // Page Up
  "CapsLock", // Used in many Windows Narrator actions
]
