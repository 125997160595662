import { graphql } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import theme from "styles/theme"

const IntroText = ({ text, extraWide = false, className }) => (
  <div
    css={{
      background: theme.colors.accentBackground,
      padding: "80px 25px",
      [theme.mq.mobile]: {
        padding: "30px 25px",
      },
    }}
    className={className}
  >
    <div css={{ maxWidth: 1228, margin: "0 auto" }}>
      <h2
        css={{
          ...theme.h3,
          position: "relative",
          borderLeft: `4px solid ${theme.colors.accentColor}`,
          paddingLeft: 20,
          // maxWidth: `${extraWide ? 22 : 19}em`,
          // maxWidth: `${extraWide ? 32 : 24}em`,
          ":before, :after": {
            content: "''",
            position: "absolute",
            width: 12,
            height: 2,
            left: 0,
            background: theme.colors.accentColor,
          },
          ":before": { top: 0 },
          ":after": { bottom: 0 },
          [theme.mq.mobile]: {
            borderLeftWidth: 2,
            paddingLeft: 12,
            ":before, :after": {
              width: 8,
              height: 1,
            },
          },
        }}
      >
        {text}
      </h2>
    </div>
  </div>
)

export default IntroText

IntroText.propTypes = {
  text: PropTypes.node.isRequired,
  extraWide: PropTypes.bool,
  className: PropTypes.string,
}

export const query = graphql`
  fragment IntroTextBlock on SanityIntroText {
    text
  }
`
