import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { useAppState } from "components/Layout"

const AutoLink = ({ href, to, target, ...rest }) => {
  const { dispatch } = useAppState()
  const url = to || href

  if (target === "_blank") rest.rel = "noopener noreferrer"

  return target || !url.startsWith("/") || /\.[a-z0-9]{1,5}$/.test(url) ? (
    <a href={url} target={target} {...rest} />
  ) : url === "/contact/" ? (
    <a
      href={url}
      {...rest}
      onClick={(e) => {
        e.preventDefault()
        rest.onClick && rest.onClick(e)
        dispatch("openContactPanel")
      }}
    />
  ) : (
    <Link to={url} {...rest} />
  )
}

export default AutoLink

AutoLink.propTypes = {
  href: PropTypes.string,
  to: PropTypes.string,
  target: PropTypes.string,
}
