import Footer from "components/Footer"
import Header from "components/Header"
import useKeyboardNavDetection from "hooks/useKeyboardNavDetection"
import PropTypes from "prop-types"
import React, { useContext, useMemo, useReducer } from "react"
import theme from "styles/theme"
import ConsentBanner from "./ConsentBanner"
import ZoomInfo from "./ZoomInfo"

export const AppContext = React.createContext()

export const useAppState = () => useContext(AppContext)

const initialState = { contactPanelOpen: false }

const appReducer = (state, actionOrActionValue) => {
  // eslint-disable-next-line no-unused-vars
  const { action, value } =
    typeof actionOrActionValue === "string"
      ? { action: actionOrActionValue }
      : actionOrActionValue

  switch (action) {
    case "openContactPanel":
      return {
        ...state,
        contactPanelOpen: true,
      }

    case "closeContactPanel":
      return {
        ...state,
        contactPanelOpen: false,
      }

    default:
      return state
  }
}

const Layout = ({ mode, currentPath, children, className }) => {
  const keyboardNavDetected = useKeyboardNavDetection()
  const [state, dispatch] = useReducer(appReducer, initialState)

  const context = useMemo(
    () => ({
      ...state,
      dispatch,
    }),
    [state, dispatch]
  )

  return (
    <div
      css={[
        {
          ...theme.modes[mode],
          color: theme.colors.primaryText,
          backgroundColor: theme.colors.primaryBackground,
        },
        !keyboardNavDetected && theme.hideOutlines,
      ]}
    >
      <AppContext.Provider value={context}>
        <Header currentPath={currentPath} />

        <main className={className}>{children}</main>

        <Footer />
        <ZoomInfo />
        <ConsentBanner />
      </AppContext.Provider>
    </div>
  )
}

export default Layout

Layout.propTypes = {
  mode: PropTypes.oneOf(["light", "dark"]).isRequired,
  currentPath: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}
