import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import SanityImage from "gatsby-plugin-sanity-image"
import GifVideo from "components/GifVideo"
import theme from "styles/theme"

const SimpleHero = ({ image, video, className, children }) => (
  <div
    css={{
      position: "relative",
      height: 650,
      maxHeight: "70vh",
      minHeight: 300,
      [theme.mq.tablet]: { height: 400, minHeight: 250 },
    }}
    className={className}
  >
    {video ? (
      <GifVideo
        src={video.asset.url}
        width="100%"
        height="100%"
        css={{
          display: "block",
          objectFit: "cover",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1,
        }}
      />
    ) : (
      <SanityImage
        {...image}
        width={1440}
        height={650}
        alt=""
        css={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: "block",
          objectFit: "cover",
          width: "100%",
          height: "100%",
        }}
        loading="eager"
        sizes="100vw"
        sources={{
          [theme.mq.mobile]: { width: 530, height: 400 },
          [theme.mq.tablet]: { width: 1100, height: 400 },
          [theme.mq.desktop]: { width: 1440, height: 650 },
        }}
      />
    )}

    {children}
  </div>
)

export default SimpleHero

SimpleHero.propTypes = {
  image: PropTypes.shape({
    asset: PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  video: PropTypes.shape({
    asset: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
  }),
  className: PropTypes.string,
  children: PropTypes.node,
}

export const query = graphql`
  fragment SimpleHeroBlock on SanitySimpleHero {
    image {
      ...ImageWithPreview
    }
    video {
      asset {
        url
      }
    }
  }
`
