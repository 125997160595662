import { graphql, useStaticQuery } from "gatsby"

export default (input) => {
  const {
    _data: { translations },
  } = useStaticQuery(graphql`
    {
      _data: allSanityTranslation {
        translations: nodes {
          match
          replacement
        }
      }
    }
  `)

  if (input === null || typeof input === "undefined") return input

  if (typeof input === "string") {
    const translation = translations.find((t) => t.match === input)
    return translation ? translation.replacement : input
  } else if (Array.isArray(input)) {
    return input.map(
      (str) => translations.find((t) => t.match === str)?.replacement || str
    )
  } else if (typeof input === "object") {
    return Object.entries(input).reduce((output, [key, str]) => {
      output[key] =
        translations.find((t) => t.match === str)?.replacement || str
      return output
    }, {})
  } else {
    throw new Error(
      `useTranslate does not support input of type ${typeof input}`
    )
  }
}
