import { useEffect, useCallback } from "react"

const useOnClickOutside = (ref, handler, active = true) => {
  const listener = useCallback(
    (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        handler(e)
      }
    },
    [ref, handler]
  )

  useEffect(() => {
    if (active) {
      document.addEventListener("click", listener)
    } else {
      document.removeEventListener("click", listener)
    }

    return () => {
      document.removeEventListener("click", listener)
    }
  }, [listener, active])
}

export default useOnClickOutside
