import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { graphql } from "gatsby"

const Metadata = ({ title, description, images = [] }) => (
  <Helmet
    htmlAttributes={{
      lang: process.env.LOCALE,
    }}
  >
    <title>{title}</title>
    <meta property="og:title" content={title} />
    {description && <meta name="description" content={description} />}
    {description && <meta property="og:description" content={description} />}
    {images
      .filter(Boolean)
      .map((image, index) => {
        const { url, width, height } = imageData(image)
        return [
          <meta key={`url-${index}`} property="og:image" content={url} />,
          <meta
            key={`width-${index}`}
            property="og:image:width"
            content={width}
          />,
          <meta
            key={`height-${index}`}
            property="og:image:height"
            content={height}
          />,
        ]
      })
      .flat()}
    <meta name="twitter:card" content="summary_large_image" />
  </Helmet>
)

export default Metadata

Metadata.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      asset: PropTypes.shape({
        url: PropTypes.string.isRequired,
        metadata: PropTypes.shape({
          dimensions: PropTypes.shape({
            width: PropTypes.number.isRequired,
            height: PropTypes.number.isRequired,
          }).isRequired,
        }).isRequired,
      }).isRequired,
    })
  ),
}

const imageData = ({ asset } = {}) => {
  if (!asset) return null

  const {
    url,
    metadata: {
      dimensions: { width, height },
    },
  } = asset

  return {
    url: url.startsWith("//") ? `https:${url}` : url,
    width,
    height,
  }
}

export const query = graphql`
  fragment Metadata on SanityPageMetadata {
    title
    description
    images {
      asset {
        url
        metadata {
          dimensions {
            height
            width
          }
        }
      }
    }
  }
`
