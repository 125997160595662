import PropTypes from "prop-types"
import { jsx } from "@emotion/core"
import theme from "styles/theme"
import AutoLink from "components/AutoLink"

const Button = ({ href, children, disabled, onClick, ...rest }) =>
  jsx(
    href ? AutoLink : "button",
    {
      css: theme.expandQueries({
        color: theme.colors.extendedUI.offsetWhite,
        background: theme.colors.core.darkPurple,
        border: 0,
        display: "inline-block",
        padding: ["11px 32px", "9px 24px"],
        fontSize: [20, 14],
        fontWeight: 400,
        lineHeight: 1.3,
        borderRadius: 2,
        textAlign: "center",
        textDecoration: "none",
        letterSpacing: "0.02em",
        cursor: "pointer",
        userSelect: "none",
        pointerEvents: disabled && "none",
        boxShadow: disabled ? theme.shadows.inset : theme.shadows.buttonTight,
        transition: "background 500ms, transform 1s ease-out",
        position: "relative",
        whiteSpace: "nowrap",
        opacity: disabled ? 0.6 : 1,
        ":after": {
          content: "''",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          boxShadow: theme.shadows.outerBlur,
          opacity: 0,
          transition: "opacity 1s",
        },
        ":hover": {
          background: theme.colors.extended.darkPurple1,
          transform: "scale(1.05) translateY(-1px)",
          ":after": {
            opacity: 1,
          },
        },
      }),
      href,
      onClick: disabled ? disabledClickHandler : onClick,
      ...rest,
    },
    children
  )

export default Button

Button.propTypes = {
  href: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

const disabledClickHandler = (e) => {
  e.preventDefault()
}
