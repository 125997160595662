import AutoLink from "components/AutoLink"
import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import theme from "styles/theme"

import { ReactComponent as Facebook } from "assets/Facebook.svg"
import { ReactComponent as Instagram } from "assets/Instagram.svg"
import { ReactComponent as LinkedIn } from "assets/LinkedIn.svg"
import { ReactComponent as Twitter } from "assets/Twitter.svg"
import { ReactComponent as YouTube } from "assets/YouTube.svg"
import { ReactComponent as AutoTechBreakthroughAward } from "assets/autotech-breakthrough-award-badge-rgb-2023.svg"
// import { ReactComponent as QecIsoIec } from "assets/qec-iso-iec-27001.svg"
import { ReactComponent as IPqalUkas } from "assets/i-pqal-ukas.svg"

const Footer = () => {
  const {
    data: { linkLists, socialLinks, legalCopy },
  } = useStaticQuery(graphql`
    {
      data: sanityFooterNavigationSettings {
        linkLists {
          _key
          title
          links {
            _key
            title
            url
          }
        }
        socialLinks {
          linkedin
          facebook
          instagram
          twitter
          youtube
        }
        legalCopy
      }
    }
  `)

  return (
    <footer
      css={{
        maxWidth: 1228,
        margin: "0 auto",
        padding: "70px 25px 125px",
        display: "grid",
        gridTemplateColumns: "repeat(6, 1fr)",
        gap: "20px 20px",
        alignItems: "start",
        [theme.mq.mobile]: {
          gridTemplateColumns: "1fr 1fr 1fr",
          padding: "25px 25px 50px",
        },
        [theme.mq.mobilePortrait]: {
          gridTemplateColumns: "1fr 1fr",
          padding: "25px 25px 50px",
        },
      }}
    >
      {/*
      <div
        css={{
          display: "flex",
          height: "100%",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
       
        <AutoLink
          to="/"
          css={{
            color: "inherit",
            display: "flex",
            alignItems: "center",
            [theme.mq.mobile]: {
              gridColumn: "1 / -1",
            },
          }}
        >
          <img src={logomark} alt="" css={{ display: "block", width: 37 }} />
        </AutoLink>
        

        <QecIsoIec
          css={{
            width: "max(2.5rem, 160px)",
            [theme.mq.mobile]: {
              width: "max(2rem, 120px)",
              marginTop: "0.5rem",
            },
          }}
        />
      </div>
      */}

      {linkLists.map(({ _key, title, links }) => (
        <div key={_key}>
          <div css={{ ...theme.p3, fontWeight: 500 }}>{title}</div>

          <ul
            css={{
              listStyle: "none",
              padding: 0,
              margin: "20px 0 0",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              [theme.mq.mobile]: {
                marginTop: 10,
              },
            }}
          >
            {links.map(({ _key, title, url }) => (
              <AutoLink
                key={_key}
                href={url}
                css={{
                  ...theme.p3,
                  display: "block",
                  color: theme.colors.secondaryText,
                  textDecoration: "none",
                  transition: "color 400ms",
                  ":hover": {
                    color: theme.colors.accentColor,
                  },
                }}
              >
                {title}
              </AutoLink>
            ))}
          </ul>
        </div>
      ))}

      <div
        css={{
          display: "flex",
          flexDirection: "column",
          gridColumn: "span 1 / -1",
        }}
      >
        <AutoTechBreakthroughAward
          css={{
            width: "max(2.5rem, 140px)",
            marginBottom: "1rem",
            [theme.mq.mobile]: {
              width: "max(2rem, 120px)",
              marginTop: "0.5rem",
            },
          }}
        />
        <IPqalUkas
          css={{
            width: "max(2.5rem, 180px)",
            [theme.mq.mobile]: {
              width: "max(2rem, 160px)",
              marginTop: "0.5rem",
            },
          }}
        />
        {/* <div css={{ display: "flex", marginTop: "1rem" }}>
          {socialLinks.twitter && (
            <SocialLink href={socialLinks.twitter} icon={Twitter} />
          )}
          {socialLinks.linkedin && (
            <SocialLink href={socialLinks.linkedin} icon={LinkedIn} />
          )}
          {socialLinks.facebook && (
            <SocialLink href={socialLinks.facebook} icon={Facebook} />
          )}
          {socialLinks.instagram && (
            <SocialLink href={socialLinks.instagram} icon={Instagram} />
          )}
          {socialLinks.youtube && (
            <SocialLink href={socialLinks.youtube} icon={YouTube} />
          )}
        </div> */}
      </div>

      <div
        css={{
          ...theme.p3,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gridColumn: "1 / -1",
          marginTop: "1em",

          [theme.mq.mobile]: {
            marginTop: "2em",
          },
        }}
      >
        {legalCopy && (
          <div css={{ opacity: 0.5, fontSize: "max(0.65rem, 12px)" }}>
            {legalCopy}
          </div>
        )}

        <div
          css={{
            display: "flex",
            alignItems: "center",
            color: theme.colors.secondaryText,
            opacity: 0.85,
          }}
        >
          <div css={{ display: "flex" }}>
            {socialLinks.twitter && (
              <SocialLink href={socialLinks.twitter} icon={Twitter} />
            )}
            {socialLinks.facebook && (
              <SocialLink href={socialLinks.facebook} icon={Facebook} />
            )}
            {socialLinks.instagram && (
              <SocialLink href={socialLinks.instagram} icon={Instagram} />
            )}
            {socialLinks.youtube && (
              <SocialLink href={socialLinks.youtube} icon={YouTube} />
            )}
            {socialLinks.linkedin && (
              <SocialLink href={socialLinks.linkedin} icon={LinkedIn} />
            )}
          </div>
          {/* <GlobeIcon css={{ marginRight: "0.5em" }} /> */}

          {/*
          [
            {
              lang: "English",
              locale: "en-GB",
              href: "https://www.secondmind.ai/",
            },
            {
              lang: "日本語",
              locale: "ja-JP",
              href: "https://www.secondmind.jp/",
            },
          ].map(({ lang, locale, href }) => (
            <React.Fragment key={locale}>
              {locale === process.env.LOCALE ? (
                <div>{lang}</div>
              ) : (
                <a
                  href={href}
                  css={{
                    color: "inherit",
                  }}
                >
                  {lang}
                </a>
              )}
              <span
                css={{
                  display: "inline-block",
                  marginLeft: "0.5em",
                  marginRight: "0.5em",
                  ":last-child": { display: "none" },
                }}
              >
                |
              </span>
            </React.Fragment>
          ))
          */}
        </div>
      </div>
    </footer>
  )
}

export default Footer

const SocialLink = ({ href, icon: Icon }) => (
  <AutoLink
    href={href}
    target="_blank"
    css={{
      color: theme.colors.accentColor,
      width: 20,
      marginRight: "1em",
      opacity: 0.8,
      transition: "opacity 300ms",
      ":last-child": { marginRight: 0 },
      ":hover": {
        opacity: 1,
      },
    }}
  >
    <Icon width={20} />
  </AutoLink>
)

SocialLink.propTypes = {
  href: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
}

// const GlobeIcon = (props) => (
//   <svg viewBox="0 0 24 24" width="22" height="22" {...props}>
//     <path
//       fill="currentColor"
//       d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-2.29-2.333A17.9 17.9 0 0 1 8.027 13H4.062a8.008 8.008 0 0 0 5.648 6.667zM10.03 13c.151 2.439.848 4.73 1.97 6.752A15.905 15.905 0 0 0 13.97 13h-3.94zm9.908 0h-3.965a17.9 17.9 0 0 1-1.683 6.667A8.008 8.008 0 0 0 19.938 13zM4.062 11h3.965A17.9 17.9 0 0 1 9.71 4.333 8.008 8.008 0 0 0 4.062 11zm5.969 0h3.938A15.905 15.905 0 0 0 12 4.248 15.905 15.905 0 0 0 10.03 11zm4.259-6.667A17.9 17.9 0 0 1 15.973 11h3.965a8.008 8.008 0 0 0-5.648-6.667z"
//     />
//   </svg>
// )
