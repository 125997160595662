export default (formName, fields) =>
  window.fetch("/", {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: serialize({
      "form-name": formName,
      ...fields,
    }),
  })

const serialize = (data) =>
  Object.keys(data)
    .map((key) => serializeProperty(key, data[key]))
    .join("&")

const serializeProperty = (key, value) => {
  if (value instanceof Set || Array.isArray(value)) {
    return Array.from(value)
      .map((value) => serializeProperty(`${key}[]`, value))
      .join("&")
  } else {
    return encodeURIComponent(key) + "=" + encodeURIComponent(value)
  }
}
