import { useEffect, useState } from "react"

export default (query) => {
  const [match, setMatch] = useState()

  useEffect(() => {
    const mm = window.matchMedia(stripMedia(query))

    const listener = (e) => setMatch(e.matches)
    mm.addListener(listener)
    setMatch(mm.matches) // Set initial value

    return () => {
      mm.removeListener(listener)
    }
  }, [query, setMatch])

  return match
}

const stripMedia = (query) =>
  query.startsWith("@media ") ? query.slice(7) : query
