import { useLocation } from "@reach/router"
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"
import React, { useEffect, useState } from "react"
import ReactDOM from "react-dom"
import { useCookie, useLocalStorage } from "react-use"
import theme from "../styles/theme"

const Portal = ({ children }) => {
  const [portalRoot, setPortalRoot] = useState(null)
  useEffect(() => {
    setPortalRoot(document.getElementById("portal"))
  }, [])

  // If portal root is null, return null
  if (!portalRoot) {
    return null
  }

  return ReactDOM.createPortal(children, portalRoot)
}

const ConsentBanner = () => {
  // Check in browser enviroment - Initialize & configure tracking
  const location = useLocation()
  if (typeof window !== "undefined") {
    initializeAndTrack(location)
  }

  // Save banner visible state to localstorage
  const [
    cookieConsentLocalStorageValue,
    setCookieConsentLocalStorageValue,
  ] = useLocalStorage("consent-hidden", false)

  // Create cookies
  // eslint-disable-next-line no-unused-vars
  const [gaCookie, updateGaCookie] = useCookie("gdpr-google-analytics")
  // eslint-disable-next-line no-unused-vars
  const [hjCookie, updateHjCookie] = useCookie("gdpr-hotjar")

  // Enable tracking services
  const EnableAnalytics = () => {
    updateGaCookie(true)
    updateHjCookie(true)

    // Update banner visible state
    setCookieConsentLocalStorageValue(true)
  }

  return (
    <Portal>
      {!cookieConsentLocalStorageValue && (
        <div
          css={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            position: "fixed",
            zIndex: 10,
            bottom: 0,
          }}
        >
          <div
            css={{
              background: theme.colors.core.lightPurple,
              borderTopLeftRadius: 4,
              borderTopRightRadius: 4,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              paddingBlock: 12,
              paddingInline: 24,
              boxShadow:
                "0px -1px 2px rgba(255, 255, 255, 0.4), 0px 3px 4px rgba(0, 0, 0, 0.15)",
            }}
          >
            <p
              css={{
                ...theme.p3,
                color: theme.colors.lightGrey,
                marginInlineEnd: 16,
              }}
            >
              We use cookies to understand how people use our website in order
              to improve your experience.
            </p>
            <button
              onClick={EnableAnalytics}
              css={{
                ...theme.p3,
                lineHeight: 1,
                appearance: "none",
                cursor: "pointer",
                border: 0,
                borderRadius: 4,
                background: theme.colors.core.darkPurple,
                color: theme.colors.core.lightGrey,
                boxShadow: theme.shadows.buttonTight,
                padding: 8,
                marginRight: 8,
                whiteSpace: "nowrap",
                fontSize: "0.7rem",
                "&:hover": {
                  background: theme.colors.extendedUI.darkPurpleInset,
                },
                [theme.mq.mobilePortrait]: {
                  fontSize: "1rem",
                },
              }}
            >
              Accept
            </button>
            <button
              onClick={() => setCookieConsentLocalStorageValue(true)}
              css={{
                ...theme.p3,
                lineHeight: 1,
                appearance: "none",
                cursor: "pointer",
                border: 0,
                borderRadius: 4,
                background: theme.colors.core.lightGrey,
                color: theme.colors.core.darkPurple,
                boxShadow: theme.shadows.tight,
                padding: 8,
                marginRight: 0,
                whiteSpace: "nowrap",
                fontSize: "0.7rem",
                "&:hover": {
                  color: theme.colors.extendedUI.darkPurpleInset,
                },
                [theme.mq.mobilePortrait]: {
                  fontSize: "1rem",
                },
              }}
            >
              Reject
            </button>
          </div>
        </div>
      )}
    </Portal>
  )
}

export default ConsentBanner
