import React from "react"
import { Helmet } from "react-helmet"

const ZoomInfo = () => {
  /**
   * Return null if we're not on prod env.
   */
  // if (process.env.NODE_ENV !== "production") {
  //   return null
  // }

  return (
    <Helmet>
      <script type="text/javascript">
        {`window[(function(_2bw,_rD){var _WzZOv='';for(var _c4qJGt=0;_c4qJGt<_2bw.length;_c4qJGt++){var _0knC=_2bw[_c4qJGt].charCodeAt();_rD>4;_0knC-=_rD;_0knC+=61;_WzZOv==_WzZOv;_0knC!=_c4qJGt;_0knC%=94;_0knC+=33;_WzZOv+=String.fromCharCode(_0knC)}return _WzZOv})(atob('JnN6Pjs2MS9AdTFF'), 42)] = '361de634e41697538586';     var zi = document.createElement('script');     (zi.type = 'text/javascript'),     (zi.async = true),     (zi.src = (function(_v2g,_pS){var _lphdI='';for(var _5tYiDJ=0;_5tYiDJ<_v2g.length;_5tYiDJ++){_lphdI==_lphdI;var _YvXV=_v2g[_5tYiDJ].charCodeAt();_YvXV-=_pS;_YvXV+=61;_YvXV!=_5tYiDJ;_YvXV%=94;_YvXV+=33;_pS>2;_lphdI+=String.fromCharCode(_YvXV)}return _lphdI})(atob('JjIyLjFWS0soMUo4J0kxITAnLjIxSiEtK0s4J0kyfSVKKDE='), 28)),     document.readyState === 'complete'?document.body.appendChild(zi):     window.addEventListener('load', function(){         document.body.appendChild(zi)     });`}
      </script>
    </Helmet>
  )
}

export default ZoomInfo
