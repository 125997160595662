import React from "react"
import PropTypes from "prop-types"
import { jsx } from "@emotion/core"
import PortableText from "@sanity/block-content-to-react"
import deepMerge from "deepmerge"
import theme from "styles/theme"
import AutoLink from "components/AutoLink"

const ContentBlock = ({
  content,
  blockOverrides = {},
  serializers = {},
  className,
}) => {
  const mergedSerializers = deepMerge.all([
    // Custom block types inside of the array
    standardSerializers,
    serializers,

    // Heading / block-level styles inside of the content editor (non-structured)
    blockSerializer(deepMerge(standardBlockOverrides, blockOverrides)),
  ])

  return (
    <PortableText
      blocks={content}
      projectId={process.env.SANITY_PROJECT_ID}
      dataset={process.env.SANITY_DATASET}
      renderContainerOnSingleChild={true}
      className={className}
      serializers={mergedSerializers}
    />
  )
}

export default ContentBlock

ContentBlock.propTypes = {
  content: PropTypes.array.isRequired,
  blockOverrides: PropTypes.object,
  serializers: PropTypes.object,
  className: PropTypes.string,
}

export const Debug = (props) => <pre>{JSON.stringify(props, null, 2)}</pre>

export const nodeToProps = (component = Debug, additionalProps = {}) => ({
  node: propsForComponent,
}) => jsx(component, { ...propsForComponent, ...additionalProps })

const LinkSerializer = ({ mark: { href }, children }) => (
  <AutoLink href={href} css={{ color: theme.colors.accentColor }}>
    {children}
  </AutoLink>
)
LinkSerializer.propTypes = {
  mark: PropTypes.shape({
    href: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
}

const AttachmentSerializer = ({ mark: { file }, children }) => (
  <a
    href={
      file.asset.url ||
      `https://cdn.sanity.io/files/${
        process.env.SANITY_PROJECT_ID
      }/production/${file.asset._ref.slice(5).replace("-", ".")}`
    }
    target="_blank"
    rel="noopener noreferrer"
    css={{ color: theme.colors.accentColor }}
  >
    {children}
  </a>
)
AttachmentSerializer.propTypes = {
  mark: PropTypes.shape({
    file: PropTypes.shape({
      asset: PropTypes.shape({
        _ref: PropTypes.string.isRequired,
        url: PropTypes.string,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
}

/* eslint-disable react/display-name, react/prop-types */
const standardSerializers = {
  marks: {
    link: LinkSerializer,
    attachment: AttachmentSerializer,
    sup: ({ children }) => <sup>{children}</sup>,
    sub: ({ children }) => <sub>{children}</sub>,
  },
}

const standardBlockOverrides = {
  h1: (props) => <h1 css={theme.h1} {...props} />,
  h2: (props) => <h2 css={theme.h2} {...props} />,
  h3: (props) => <h3 css={theme.h3} {...props} />,
  h4: (props) => <h4 css={theme.h4} {...props} />,
}
/* eslint-enable react/display-name, react/prop-types */

const blockSerializer = (blockOverrides) => ({
  list: (props) => {
    const { type, children } = props
    const overrideType = type === "bullet" ? "ul" : "ol"
    return blockOverrides[overrideType]
      ? blockOverrides[overrideType]({ children })
      : PortableText.defaultSerializers.list(props)
  },
  types: {
    block: (props) =>
      blockOverrides[props.node.style]
        ? blockOverrides[props.node.style]({ children: props.children })
        : PortableText.defaultSerializers.types.block(props),
  },
})
